import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/style";

const HeroV8 = ({
  heroImage,
  heroTitleTop,
  heroTitleBottom,
  excerpt,
  btnLeft,
  btnLeftLink,
  btnRight,
  btnRightLink,
}) => {
  /* console.log(
    "Hero Data: ",
    heroImage,
    heroTitleTop,
    heroTitleBottom,
    excerpt,
    btnLeft,
    btnLeftLink,
    btnRight,
    btnRightLink
  ); */
  //console.log("heroImage: ", heroImage);

  return (
    <div className={`z-200 bg-[#1d1d1d]`}>
      <section className="showcase">
        {/* Background image */}
        <GatsbyImage image={heroImage} alt="" className="w-[100%] h-[100%]" />
        <div className="flex h-full items-center justify-center overlay">
          <div className="px-6 text-center text-white md:px-12">
            <div className="flex items-center justify-center w-full ">
              <div
                className="ss:flex hidden text-[#FFFFFF] text-2xl  mr-2 mb-1"
                style={{ maxWidth: "78px" }}
              >
                <StaticImage
                  src="../images/logos/intact-electrical-logo-outline-v2.png"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                  alt="Robot"
                  //aspectRatio={1.0}
                  loading="lazy"
                />
              </div>
              <div
                //className="ss:hidden flex text-[#FFFFFF] text-2xl font-bold"
                //className="mb-8"
                style={{ maxWidth: "620px" }}
              >
                <div className="w-full flex flex-col sm:text-left ss:text-left">
                  {heroTitleTop ? (
                    <span
                      className={`${styles.heading4}`}
                      //className="heroTitle"
                      //style={{ margin: "0px", padding: "0" }}
                    >
                      {heroTitleTop}
                    </span>
                  ) : (
                    <span
                      //className="heroTitle"
                      className={`${styles.heading4}`}
                    >
                      Default Top Title
                    </span>
                  )}

                  {heroTitleBottom ? (
                    <span
                      //className="heroTitle"
                      className={`${styles.heading4}`}
                    >
                      {heroTitleBottom}
                    </span>
                  ) : (
                    <span className={`${styles.heading4}`}>
                      Default Bottom Title
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${styles.heroExcerpt}`}
              style={{ maxWidth: "550px" }}
            >
              <p>{excerpt}</p>
            </div>

            <div
              className="flex items-center justify-center w-full mb-6 mt-4"
              style={{ maxWidth: "500px" }}
            >
              <a
                href={btnLeftLink}
                aria-label=""
                //className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                <button
                  type="button"
                  className="mt-4 mr-6 rounded border-2 border-neutral-50 px-[20px] pt-[6px] pb-[6px] text-sm font-medium leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  {btnLeft}
                </button>
              </a>
              <a
                href={btnRightLink}
                aria-label=""
                //className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                <button
                  type="button"
                  className="mt-4 rounded border-2 border-neutral-50 px-[20px] pt-[6px] pb-[6px] text-sm font-medium leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  {btnRight}
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroV8;
