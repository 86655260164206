import React from "react";
import styles from "../styles/style";

const Title = ({ titleTop, titleBottom }) => {
  //console.log("Details: ", titleTop, titleBottom);
  return (
    <h2 className={`${styles.heading3} mb-4`}>
      {titleTop ? (
        <span className="text-gray-700">{titleTop}</span>
      ) : (
        <span className="text-gray-700">Default Top Title</span>
      )}
      {/* <br className="xx:block hidden" />{" "} */}
      <br />
      {titleBottom ? <span className="text-dimGrey">{titleBottom}</span> : null}
    </h2>
  );
};

export default Title;
