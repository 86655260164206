import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "../styles/style.js";
import Title from "./Title.js";
import Button from "./Button";
import { Link } from "gatsby";

const FeaturedContent = ({
  featuredContent: {
    id,
    titleTop,
    titleBottom,
    content1,
    content2,
    buttonLabel,
    link,
  },
  featuredContentImages,
}) => {
  //console.log("featuredContentImages: ", featuredContentImages);

  //console.log("First image: ", firstImage[0].name);
  //console.log("Featured Content Images: ", featuredContentImages);

  /* const { id, titleTop, titleBottom, content1, content2, buttonLabel, link } =
    featuredContent; */

  const images = featuredContentImages;
  //console.log("images: ", images);

  let imagesArray = images;

  // get first image
  let firstImage = imagesArray.slice(0, 1);
  //console.log("First image: ", firstImage[0].name);

  // get second image
  let secondImage = imagesArray.slice(1, 2);
  //console.log("Second images: ", secondImage[0].name);

  // get the final image
  let finalImage = imagesArray.slice(-1);
  //console.log("Final image: ", finalImage[0].name);

  return (
    <div
      id={id}
      className={`bg-[#ffffff] mt-4 mb-4 ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <div className="mt-4">
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl">
                <Title titleTop={titleTop} titleBottom={titleBottom} />
                <p className={`${styles.paragraph2} mt-2`}>
                  {content1}
                </p>
                {content2 ? (
                  <p className={`${styles.paragraph2} mt-2`}>
                    {content2}
                  </p>
                ) : null}
              </div>
              <div className="">
                <Link
                  to={link}
                  alt="contact us link"
                  aria-label="contact us link"
                  className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                >
                  <Button label={buttonLabel} styles={`mt-4`} />
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
              <div className="flex flex-col items-end px-3">
                <GatsbyImage
                  image={firstImage[0].childImageSharp.gatsbyImageData}
                  alt={finalImage[0].name}
                  className="object-cover mb-6 rounded-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                />
                <GatsbyImage
                  image={secondImage[0].childImageSharp.gatsbyImageData}
                  alt={finalImage[0].name}
                  className="object-cover w-20 h-20 rounded-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                />
              </div>
              <div className="px-3">
                <GatsbyImage
                  image={finalImage[0].childImageSharp.gatsbyImageData}
                  alt={finalImage[0].name}
                  className="object-cover w-40 h-40 rounded-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedContent;
