import React from "react";

const Button = ({ styles, label }) => (
  <button
    type="button"
    className={`py-2 px-4 font-medium text-[18px] bg-dark-grey-radial-gradient text-[#ffffff] rounded-[10px] outline-none ${styles} hover:text-quinary `}
  >
    {label}
  </button>
);

export default Button;
