import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
//import { apple, bill, google, roof } from "../images";
import styles, { layout } from "../styles/style";
import Button from "./Button";
import Title from "./Title";
import { Link } from "gatsby";

const PageAdvert = ({
  ad: {
    id,
    imgLeft,
    titleTop,
    titleBottom,
    buttonLabel,
    link,
    src,
    content1,
    content2,
  },
  image,
}) => {
  //console.log("ID: ", id);
  //console.log("title Top: ", titleTop);
  // console.log("title Bottom: ", titleBottom);
  //console.log("buttonLabel: ", buttonLabel);
  //console.log("src: ", src);
  // console.log("content1: ", content1);
  // console.log("content2: ", content2);
  //console.log("ImgLeft: ", imgLeft);

  return (
    <div>
      {imgLeft === true ? (
        <div id={id} className={` ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <section className={`${layout.sectionReverse} relative`}>
              <div className="absolute z-[0] w-[30%] h-[30%] -left-[40%] rounded-full bottom-20" />
              <div className={` ${layout.sectionImgReverse}`}>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={image.name}
                  className=""
                  style={{
                    fit: "cover",
                    cropFocus: "attention",
                    borderRadius: "10px",
                  }}
                />

                {/* gradient start */}
                <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient" />
                <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient" />
                {/* gradient end */}
              </div>

              <div className={layout.sectionInfo}>
                <Title titleTop={titleTop} titleBottom={titleBottom} />
                <p
                  className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%]`}
                >
                  {content1}
                </p>
                <p
                  className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-2`}
                >
                  {content2}
                </p>

                <Link 
                  to={link} 
                  alt={buttonLabel}
                  aria-label={buttonLabel}
                >
                  <Button label={buttonLabel} styles={`mt-4`} />
                </Link>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div
          id={id}
          className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}
        >
          <div className={`${styles.boxWidth}`}>
            <section className={layout.section}>
              <div className={layout.sectionInfo}>
                <Title titleTop={titleTop} titleBottom={titleBottom} />
                <p
                  className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%]`}
                >
                  {content1}
                </p>
                <p
                  className={`${styles.paragraph2} lg:max-w-[570px]  max-w-[100%] mt-2`}
                >
                  {content2}
                </p>
                <Link 
                  to={link} 
                  alt={buttonLabel}
                  aria-label={buttonLabel}
                >
                  <Button label={buttonLabel} styles={`mt-4`} />
                </Link>
              </div>

              <div className={layout.sectionImg}>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={image.name}
                  className=""
                  style={{
                    fit: "cover",
                    cropFocus: "attention",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdvert;
